@import "../../global.scss";


.footer {
  height: 50px;
  background-color: $mainColor;
  color: white;
  padding: 5px;
  display: flex;
  flex-direction: row;
  font-size: 12px;

  .left {
    padding-left: 10px;
    width: 33%;
    margin: auto;
    .big-text{
      font-size: 14px;
    }
  }

  .middle {
    //width: 34%;
    margin: auto;
    display: flex;
    justify-content: center;
    font-size: 12px;
    .madewithlove{
      display: inline-flex;
      font-size: 14px;
      .red{
        color: red;
      }
    }
  }

  .right {
    width: 33%;
    margin: auto;
    display: flex;
    justify-content: center;
  }
}
