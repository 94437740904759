@import "../../global.scss";

.portfolio {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 50px;

    @include mobile{
      font-size: 40px;
    }
  }

  ul {
    margin: 10px;
    padding: 0;
    list-style: none;
    display: flex;

    @include mobile{
      margin: 10px 0;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .para{
    margin-top: 12px;
  }

  .first{
    margin-top: 30px;
  }

  .resume{
    margin-top: 0px;
    display: contents !important;
    cursor: pointer;
    color: rgb(73, 72, 72);
    
    a{
      img{
        width: 70px;
        margin-left: 6px;
        margin-top: 15px;
        opacity: 0.5;
      }
      text-decoration: none;
      color: inherit;
    }
  }

  .description{
    display: flex;
    width: 60%;
    text-align: justify;
    line-height: 1.5;
    font-size: 18px;

    @include mobile{
      width: 70%;
      text-align: justify;
      line-height: 1.5;
      font-size: 14px;
    }
  }

  .container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @include mobile{
      width: 100%;
    }

    .item {
      width: 200px;
      height: 200px;
      border-radius: 100px;
      border: 1px solid rgb(240, 239, 239);
      margin: 0px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      position: relative;
      transition: all .5s ease;
      cursor: pointer;

      @include mobile{
        width: 140px;
        height: 140px;
      }

      h3 {
        position: absolute;
        font-size: 20px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
      }

      &:hover {
        background-color: $mainColor;
        img {
          opacity: 0.2;
          z-index: 0;
        }
      }
    }
  }

  .contact-container {
    margin: 20px 10px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @include mobile{
      width: 90%;
    }

    .item {
      width: 40px;
      height: 40px;
      // border-radius: 10px;
      // border: 1px solid rgb(240, 239, 239);
      margin: 2px 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      position: relative;
      transition: all .5s ease;
      cursor: pointer;
      text-align: center;

      @include mobile{
        width: 30px;
        height: 30px;
        margin: 1px 6px;

        h3{
          position: absolute;
          font-size: 10px !important;
        }
      }

      h3 {
        position: absolute;
        font-size: 20px;
        img{
          display: none;
        }
      }

      img {
        width: 80%;
        object-fit: cover;
        z-index: 1;
      }

      // &:hover {
      //   background-color: $mainColor;
      //   img {
      //     opacity: 0.2;
      //     z-index: 0;
      //   }
      // }
    }
  }
}
