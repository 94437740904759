@import "../../global.scss";

.works {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 50px;
    height: 17%;
    @include mobile{
      font-size: 40px;
      height: 10%;
    }
  }

  .ampersent{
    font-weight: 300;
  }

  ul {
    margin: 10px;
    padding: 0;
    list-style: none;
    display: flex;

    @include mobile{
      margin: 10px 0;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .container {
    margin: 10px 10px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @include mobile{
      width: 90%;
    }

    .item {
      width: 100px;
      height: 100px;
      border-radius: 10px;
      border: 1px solid rgb(240, 239, 239);
      margin: 1% 1%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      position: relative;
      transition: all .5s ease;
      cursor: pointer;
      text-align: center;

      @include mobile{
        width: 65px;
        height: 65px;

        h3{
          position: absolute;
          font-size: 10px !important;
        }
      }

      h3 {
        position: absolute;
        font-size: 20px;
      }

      img {
        width: 80%;
        object-fit: cover;
        z-index: 1;
      }

      &:hover {
        background-color: $mainColor;
        img {
          opacity: 0.2;
          z-index: 0;
        }
      }
    }
  }
}
