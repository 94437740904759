@import "../../global.scss";

.contact {
  background-color: white;
  display: flex;

  @include mobile {
    flex-direction: column;
  }

  .left {
    flex: 1;
    overflow: hidden;

    img {
      height: 100%;
    }
  }
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      font-size: 30px;
    }

    form {
      width: 70%;
      height: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      input {
        width: 300px;
        height: 30px;
        font-size: 14px;

        @include mobile {
          width: 200px;
          height: 20px;
        }
      }

      textarea {
        width: 300px;
        height: 140px;
        font-size: 14px;

        @include mobile {
          width: 200px;
          height: 100px;
        }
      }

      button {
        width: 300px;
        height: 40px;
        color: white;
        background-color:black;
        border: none;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 300;
        cursor: pointer;

        @include mobile {
          width: 210px;
        }

        &:focus {
          outline: none;
        }
      }

      span {
        color: rgb(58, 58, 58);

        .big{
          font-size: 30px;
        }
      }
    }
  }
}
