@import "../../global.scss";

.certifications {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mobile {
    justify-content: space-around;
  }


  h1 {
    font-size: 50px;

    @include mobile {
      font-size: 40px;
    }
  }

  .container {
    width: 80%;
    // height: 44%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 2%;

    @include mobile {
      flex-direction: column;
      height: 100%;
    }

    .card {
      margin: 10px;
      width: 250px;
      //height: 40%;
      border-radius: 10px;
      box-shadow: 0px 0px 15px -8px black;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding: 20px;
      transition: all 1s ease;
      position: relative;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%);
      background-size: cover;
      background-repeat: no-repeat;

      @include mobile {
        //height: 180px;
        margin: 10px 0;
      }

      &:hover {
        transform: scale(1.1);
      }

      .left {
        width: 40%;

        img {
          width: 100%;
          // -webkit-filter: grayscale(100%);
          // /* Safari 6.0 - 9.0 */
          // filter: grayscale(100%);
        }
      }

      .right {
        width: 60%;
        padding: 5px 10px;

        h4 {
          height: 40px;
        }

        .authority {
          color: gray;
          font-size: small;
          height: 100px;
        }

        a {
          margin-top: 5px;
          font-size: small;
          text-decoration: none;
          background-color: $mainColor;
          color: white;
          border: 1.5px solid $mainColor;
          border-radius: 20px;
          display: inline-flex;
          height: 20px;
          cursor: pointer;
          transition: all .5s ease;

          span {
            width: 80px;
            padding: 2px;
            display: flex;
            justify-content: center;

            img {
              transition: all .5s ease;
              filter: invert(1);
            }
          }

          &:hover {
            background-color: white;
            border: 1.5px solid;
            color: $mainColor;

            span {
              img {
                transition: all .5s ease;
                filter: invert(0);
              }
            }
          }
        }

      }


      // .bottom {
      //   display: flex;
      //   align-items: ceter;
      //   flex-direction: column;
      //   justify-content: center;

      //   h3 {
      //     margin-bottom: 5px;

      //     @include mobile{
      //       font-size: 14px;
      //     }
      //   }
      //   h4{
      //       color: rgb(121, 115, 115);
      //       @include mobile{
      //         font-size: 13px;
      //       }
      //   }
      // }
    }
  }
}