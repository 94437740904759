@import "../../global.scss";

.intro {
  background-color: white;
  display: flex;

  @include mobile{
    flex-direction: column;
    align-items: center;
  }

  button {
    margin-top: 2%;
    width: 80px;
    height: 40px;
    color: white;
    background-color:black;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 300;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    @include mobile{
      margin-top: 26%;
    }
  }

  .left {
    flex: 0.5;
    overflow: hidden;

    .imgContainer {
      width: 700px;
      height: 100%;
      //background-color: crimson;
      //border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      float: right;

      @include mobile{
        align-items: center;
        height: 430px !important;
        .bigText{
          font-size: 64px !important;
        }
      }

      img {
        height: 90%;

        @include mobile{
          height: 50%;
        }
      }

      .bigText{
        font-size: 110px;
        color: gray;
        font-weight: 60;
      }
    }
  }
  .right {
    flex: 0.5;
    position: relative;

    .wrapper {
      width: 100%;
      height: 100%;
      padding-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include mobile{
        padding-left: 0;
        align-items: center;
        height: 60%;
      }

      h1 {
        font-size: 60px;
        margin: 10px 0;

        @include mobile{
          font-size: 40px;
        }
      }
      h2 {
        font-size: 35px;
      }
      h3 {
        font-size: 30px;
        font-weight: 400;

        @include mobile{
          font-size: 20px;
        }

        span {
          font-size: inherit;
          // color: rgb(202, 0, 0);
          color: rgb(148, 147, 147);
        }

        .ityped-cursor {
          animation: blink 1s infinite;
        }

        @keyframes blink {
            50%{
                opacity: 1;
            }
            100%{
                opacity: 0;
            }
        }
      }
    }
    a.arrow {
      position: absolute;
      bottom: 10px;
      left: 45%;

      img {
        width: 30px;
        animation: arrowBlink 2s infinite;
      }

      @keyframes arrowBlink {
        100% {
          opacity: 0;
        }
      }
    }
  }
}
