@import "../../global.scss";

.contact-container {
    margin: 20px 10px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @include mobile {
        width: 90%;
    }

    .item {
        width: 40px;
        height: 40px;
        // border-radius: 10px;
        // border: 1px solid rgb(240, 239, 239);
        margin: 2px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        position: relative;
        transition: all .5s ease;
        cursor: pointer;
        text-align: center;

        @include mobile {
            width: 30px;
            height: 30px;
            margin: 1px 6px;

            h3 {
                position: absolute;
                font-size: 10px !important;
            }
        }

        h3 {
            position: absolute;
            font-size: 20px;

            img {
                display: none;
            }
        }

        img {
            width: 80%;
            object-fit: cover;
            z-index: 1;
        }

        // &:hover {
        //   background-color: $mainColor;
        //   img {
        //     opacity: 0.2;
        //     z-index: 0;
        //   }
        // }
    }
}
